import AiService from '@/services/ai/ai.service.js'
import SvgIcon from '@/components/vue-chat/components/SvgIcon/SvgIcon'
import { v4 as uuidv4 } from 'uuid'
import getEnv from '@/util/env'
import moment from 'moment'
import { TextTag } from '@/classes/TextTag'
import * as linkify from 'linkifyjs'
import { AgentType } from '@/models/web-chat/AgentType'
import { TextFormatParser } from '@/classes/TextFormatParser.js'

const { io } = require('socket.io-client')

export default {
  name: 'WebChat',
  components: {
    SvgIcon,
  },

  data () {
    return {
      text: '',
      runId: '',
      threadId: null,
      messages: [],
      loadingResponse: false,
      assistantModelId: null,
      showChat: false,
      preparingChat: false,
      uniqueId: '',
      webChatReady: false,
      webchatConfig: {
        origin: null,
        headerMessage: null,
        welcomeMessage: null,
        inputPlaceholder: null,
        msgUser: '#49a249',
        msgBot: '#ffffff',
        msgUserTxt: '#ffffff',
        msgBotTxt: '#000000',
        bgTopBar: '#739335',
        bgTopBarHover: '#82a342',
        chatBg: '#d1f0d1',
        footerBg: '#49a249',
      },
      textProps: {
        show: false,
        x: 0,
        y: 0,
      },
      textTag: null,
      textFormatParser: new TextFormatParser(),
      menu: false,
      reactionTypes: [
        { type: 1, icon: 'fas fa-thumbs-up' },
        { type: 2, icon: 'fas fa-thumbs-down' },
      ],
    }
  },
  methods: {
    rateMessage (message, star) {
      if (message.interactive_message_data.rated) return
      message.interactive_message_data.rate = star
      this.$forceUpdate()
    },

    setRatingToMessage (message) {
      message.interactive_message_data.rated = true
      AiService.setRatingToMessage({ message: message }).then((res) => {
        console.log(res)
        this.$forceUpdate()
      })
    },

    outTextAreaMessage () {
      this.textProps.show = false
    },
    parseMessage (message) {
      message = this.textFormatParser.formatHtmlMessage(message)
      const links = linkify.find(message)
      links.forEach(link => {
          message = this.generateLink(message, link)
      })
     return message
    },
    addFormatText (type) {
      const replaced = this.textTag.addFormatText(type)
      this.text = replaced || this.textTag.textElement.value
    },
    generateLink (message, link) {
      const linkedText = message
          .substring(link.start)
          .replace(link.value, `<a href="${link.href}" target="_blank">${link.value}</a>`)
      return message.substring(0, link.start) + linkedText
    },
    getMessages () {
      this.loadingResponse = true
      AiService.getWebChatMessagesByUniqueId({ uniqueId: this.uniqueId }).then((messages) => {
        this.loadingResponse = false
        this.messages = messages.map((message) => {
          message.parsedMessage = this.parseMessage(message.message)
          if (message.message_type === 2) {
            message.interactive_message_data = message.interactive_message_data ? JSON.parse(message.interactive_message_data) : null
            message.interactive_message_data.rated = false
            message.interactive_message_data.rate = null
          }

          message.menuOpen = false
          return message
        })
        this.setWelcomeMessage()
        this.scrollDownSmooth()
      })
    },

    scrollDownSmooth () {
      const messagesContainer = document.getElementById('messages-container')
      setTimeout(() => {
        messagesContainer.scrollTo({
          top: messagesContainer.scrollHeight,
          behavior: 'smooth',
        })
      }, 200)
    },

    createMessageWithParams () {
      if (!this.text || this.text === '') return
      this.loadingResponse = true
      const text = this.text + ''
      this.text = ''
      const messageInput = this.$refs.messageInput
      AiService.savePublicWebChatMessage({
        text: text,
        origin: this.webchatConfig.origin,
        uniqueId: this.uniqueId,
        initiKey: this.$route.query.initiKey,
      }).then((res) => {
        this.messages.push({
          id: uuidv4(),
          is_contact: true,
          message: text,
          parsedMessage: this.parseMessage(text),
          created_at: moment(),
        })
        this.scrollDownSmooth()
        if (this.agentTypeId === AgentType.HUMAN) {
          this.loadingResponse = false
        }
      })
      .finally(() => {
        messageInput.focus()
      })
    },


    setReactionToMessage (message, reactionType) {
      this.loadingResponse = true
      const preMessageReactionValue = reactionType
      AiService.reactToMessage({
        text: message.parsedMessage,
        origin: this.webchatConfig.origin,
        uniqueId: this.uniqueId,
        initiKey: this.$route.query.initiKey,
        baseMessageId: message.id,
        reactionType: preMessageReactionValue,
      }).then((res) => {
        message.reaction_type = preMessageReactionValue
      })
      .finally(() => {
        this.$refs.messageInput.focus()
        this.loadingResponse = false
        message.menuOpen = false
      })
    },

    toggleChatContainer () {
      this.preparingChat = true
      // console.log('showChat', this.showChat)
      this.showChat = !this.showChat
      if (this.showChat) {
        // console.log('message-out', 'open')
        window.parent.postMessage('open-webchat', '*')
      } else {
        // console.log('message-out', 'close')
        window.parent.postMessage('close-webchat', '*')
      }
      this.preparingChat = false
      // if (this.showChat) {
      //   document.getElementById('chat-container').classList.remove('animate-hide')
      //   document.getElementById('chat-container').classList.add('animate-bottom')
      //   setTimeout(() => {
      //     document.getElementById('chat-container').classList.addremove('mt-0')
      //     document.getElementById('chat-container').classList.remove('container-margin-top')
      //     this.preparingChat = false
      //     this.scrollDownSmooth()
      //   }, 800)
      // } else {
      //   document.getElementById('chat-container').classList.remove('animate-bottom')
      //   document.getElementById('chat-container').classList.add('animate-hide')
      //   document.getElementById('chat-container').classList.add('container-margin-top')
      //   document.getElementById('chat-container').classList.remove('mt-0')
      //   setTimeout(() => {
      //     this.preparingChat = false
      //   }, 800)
      // }
    },
    setWelcomeMessage () {
      this.messages.unshift({
        id: uuidv4(),
        is_contact: false,
        message: this.webchatConfig.welcomeMessage,
        parsedMessage: this.parseMessage(this.webchatConfig.welcomeMessage),
      })
    },
    setConfig () {
      const userConfig = JSON.parse(this.$route.query.config)
      this.webchatConfig = {
        ...this.webchatConfig,
        ...userConfig,
      }
      this.webChatReady = true
    },
  },
  computed: {
    headerMessage () {
      return this.webchatConfig.headerMessage ?? this.$t('Tienes una pregunta?')
    },
    inputPlaceholder () {
      return this.webchatConfig.inputPlaceholder ?? this.$t('Escribe un mensaje...')
    },
  },
  created () {
  },
  mounted () {
    let uniqueId = localStorage.getItem('uniqueId')
    if (!uniqueId) {
      const generatedUniqueId = uuidv4()
      uniqueId = generatedUniqueId
      localStorage.setItem('uniqueId', generatedUniqueId)
    }

    this.uniqueId = uniqueId

    this.setConfig()
    AiService.getAssistantByUniqueKey({
      origin: this.webchatConfig.origin,
      initiKey: this.$route.query.initiKey,
      uniqueId: this.uniqueId,
    }).then((assistant) => {
        // console.log(assistant)
        this.assistantModelId = assistant.id
        this.agentTypeId = assistant.agent_type_id
        this.assistantId = assistant.assistant_id
        this.webchatConfig.welcomeMessage = assistant.welcome_message
        this.getMessages()
    })

    const websocketUrl = getEnv('backend_websocket_url')
    // console.log(websocketUrl)
    // const user = JSON.parse(localStorage.getItem('user'))

    if (!this.socket) {
      this.socket = io(websocketUrl, {
        transports: ['websocket'],
        auth: {
          service: 'WEBCHAT',
          uniqueId: this.uniqueId,
        },
      })
      // console.log('webchat-out', this.socket)
    }

    this.socket.on('webchat-message-out', (message) => {
      console.log('webchat out', [message])

      if (message.type === 'changeAgent') {
        this.agentTypeId = message.agent_type_id
        this.assistantId = message.agent_id
        return
      }

      message.parsedMessage = this.parseMessage(message.message)
      if (message.message_type === 2) {
        message.interactive_message_data = message.interactive_message_data ? JSON.parse(message.interactive_message_data) : null
      }
      this.messages.push(message)
      this.scrollDownSmooth()
      if (this.agentTypeId === AgentType.AI && message.is_contact === false) {
        this.loadingResponse = false
      }
      // console.log('webchat out messages', this.messages)
    })

    this.socket.on('connect', () => {

    })

    this.socket.on('disconnect', () => {

    })

    setTimeout(() => {
      this.textTag = new TextTag(document.getElementById('messageInput'))
      document.getElementById('messageInput').addEventListener('mouseup', e => {
        const from = this.textTag.textElement.selectionStart
        const to = this.textTag.textElement.selectionEnd
        let newStart = from
        let newEnd = to

        while (this.textTag.textElement.value.substring(newStart, newStart + 1) === ' ') {
          newStart++
        }

        while (this.textTag.textElement.value.substring(newEnd - 1, newEnd) === ' ') {
          newEnd--
        }

        this.textTag.textElement.setSelectionRange(newStart, newEnd)
        const textSelected = this.textTag.textElement.value.substring(from, to)

        if (textSelected.length > 0) {
          this.textProps.x = e.clientX - 600
          this.textProps.show = true
        } else {
          this.textProps.show = false
        }
      })
    }, 2000)
  },
}
